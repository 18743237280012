
export default {
  name: "Menu",
  async fetch() {
    await this.$store.dispatch('useCases/fetchUseCases', this.$i18n.locale || 'en');
  },
  fetchOnServer: true,
  data() {
    return {
      toggleMobileMenu: false,
      isLanguageMenuOpen: false,
      year: null,
      openSubmenus: [],
    };
  },
  computed: {
    useCasesChildren() {
      return this.$store.state.useCases.useCases[this.$i18n.locale] || [];
    },
    availableLocales() {
      return this.$i18n.locales.filter(locale => locale.code !== this.$i18n.locale);
    },
    pages() {
      return {
        data: [
          {id: 1, attributes: {title: 'Product', href: '/product/'}, prioritize: true},
          {
            id: 2,
            attributes: {
              title: 'Use Cases',
              href: '',
              children: this.useCasesChildren,
            },
            prioritize: true,
          },
          {id: 3, attributes: {title: 'Templates', href: '/templates/'}, prioritize: true},
          {id: 4, attributes: {title: 'Blog', href: '/blog/'}, prioritize: true},
          {id: 5, attributes: {title: 'Pricing', href: '/pricing/'}, prioritize: false, addMargin: true},
        ],
      };
    },
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished;
    },
    showNav() {
      return this.$store.state.showNav;
    },
    getImageSrc() {
      if (this.$colorMode.value === 'dark') {
        return '/icon_white.png';
      } else {
        return '/icon.png';
      }
    },
  },
  watch: {
    '$route'(to, from) {
      if (this.toggleMobileMenu) {
        this.triggerMobileNavAnimation(true);
      }
      this.$store.commit('setShowNav', true);
    },
    '$i18n.locale'(newLocale) {
      this.$store.dispatch('useCases/fetchUseCases', newLocale);
    },
  },
  mounted() {
    const now = new Date();
    this.year = now.getFullYear();
    this.initializeMenuOverlayAnimation();
    this.initializeMenuLinksAnimation();
  },
  methods: {
    toggleLanguageMenu() {
      this.isLanguageMenuOpen = !this.isLanguageMenuOpen;
    },
    initializeMenuOverlayAnimation() {
      // Anime js requires initial state to be set
      this.$anime.set(this.$refs['menuOverlay'], {
        translateX: '100%',
      });
    },
    initializeMenuLinksAnimation() {
      // Anime js requires initial state to be set
      const menuLinks = document.querySelectorAll('.menu-link');
      menuLinks.forEach((link) => {
        this.$anime.set(link, {
          translateY: '120%',
          opacity: 0,
        });
      });
    },
    triggerMobileNavAnimation(close = false) {
      this.toggleMobileMenu = !this.toggleMobileMenu;

      if (this.toggleMobileMenu && !close) {
        document.body.classList.add('is-nav-open');
        this.menuOverlayAnimation(true);
        this.menuLinksAnimation(true);
      } else {
        this.menuOverlayAnimation(false);
        this.menuLinksAnimation(false);
        document.body.classList.remove('is-nav-open');
        // Close all open submenus when the mobile menu is closed
        this.openSubmenus = [];
      }
    },
    menuOverlayAnimation(show) {
      this.$anime({
        targets: this.$refs['menuOverlay'],
        translateX: show ? '0%' : '100%',
        duration: 500,
        easing: 'easeInOutQuad',
      });
    },
    menuLinksAnimation(show) {
      this.$anime({
        targets: '.menu-link',
        translateY: show ? '0%' : '120%',
        opacity: show ? 1 : 0,
        duration: 400,
        easing: 'easeInOutQuad',
        delay: this.$anime.stagger(60, {start: show ? 500 : 0}),
      });
    },
    // handleLinkClick(page) {
    //   if (page.attributes.children) {
    //     this.toggleSubmenu(page.id);
    //   } else {
    //     this.triggerMobileNavAnimation();
    //   }
    // },
    toggleSubmenu(id) {
      const index = this.openSubmenus.indexOf(id);
      if (index > -1) {
        this.openSubmenus.splice(index, 1);
      } else {
        this.openSubmenus.push(id);
      }
    },
    isSubmenuOpen(id) {
      return this.openSubmenus.includes(id);
    },
  },
};
