// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".submenu_gkB4H:before{content:\"\";height:20px;left:0;margin-left:0;margin-right:0;margin-top:-28px;position:absolute;right:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submenu": "submenu_gkB4H"
};
module.exports = ___CSS_LOADER_EXPORT___;
