// plugins/swiper.js
import Vue from 'vue';
import Swiper, {EffectFade, Autoplay} from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-fade/effect-fade.scss';

const swiper = {
  install(Vue, options) {
    Vue.prototype.$swiper = Swiper;
    Vue.prototype.$swiperModules = {
      EffectFade,
      Autoplay,
    };
  }
};

Vue.use(swiper);
