import GetAllComparisons from '@/apollo/queries/comparison/posts.gql';
import {defaultLocale, i18nPages} from "@/i18n.config.js";

export const state = () => ({
  comparisons: {},
});

export const mutations = {
  setComparisons(state, { locale, comparisons }) {
    state.comparisons = {
      ...state.comparisons,
      [locale]: comparisons,
    };
  },
};

export const actions = {
  async fetchComparisons({ commit, state }, locale) {
    if (state.comparisons[locale]) {
      // Comparisons already fetched for this locale
      return;
    }
    try {
      const client = this.app.apolloProvider.defaultClient;
      const { data } = await client.query({
        query: GetAllComparisons,
        variables: { locale },
      });

      const comparisons = data.comparisonPosts.data.map((comparison) => ({
        title: comparison.attributes.title,
        mobileTitle: comparison.attributes.mobileTitle || comparison.attributes.title,
        href: `${locale === defaultLocale ? '' : '/' + locale}${i18nPages['comparison/_slug'][locale].replace(':slug', comparison.attributes.slug)}`,
      }));

      commit('setComparisons', { locale, comparisons });
    } catch (error) {
      console.error('Error fetching comparisons:', error);
    }
  },
};
