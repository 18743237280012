import Vue from 'vue';
import Fuse from 'fuse.js';

// Optionally, configure Fuse.js options globally
const options = {
  keys: ['title', 'content'],
  threshold: 0.4,
  includeScore: true,
};

// Initialize Fuse.js with an empty array; you'll set the list in components
const fuse = new Fuse([], options);

// Make Fuse.js available throughout the application via this.$fuse
Vue.prototype.$fuse = fuse;
