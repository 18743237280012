import GetAllUseCases from '@/apollo/queries/useCase/posts.gql';
import {defaultLocale, locales, i18nPages} from "@/i18n.config.js";

export const state = () => ({
  useCases: {},
});

export const mutations = {
  setUseCases(state, { locale, useCases }) {
    state.useCases = {
      ...state.useCases,
      [locale]: useCases,
    };
  },
};

export const actions = {
  async fetchUseCases({ commit, state }, locale) {
    if (state.useCases[locale]) {
      // Use cases already fetched for this locale
      return;
    }
    try {
      const client = this.app.apolloProvider.defaultClient;
      const { data } = await client.query({
        query: GetAllUseCases,
        variables: { locale },
      });

      const useCases = data.useCasePosts.data.map((useCase) => ({
        title: useCase.attributes.title,
        mobileTitle: useCase.attributes.mobileTitle || useCase.attributes.title,
        href: `${locale === defaultLocale ? '' : '/' + locale}${i18nPages['use-cases/_slug'][locale].replace(':slug', useCase.attributes.slug)}`,
      }));

      commit('setUseCases', { locale, useCases });
    } catch (error) {
      console.error('Error fetching use cases:', error);
    }
  },
};
