export default ({ app }, inject) => {
  // Custom localePath with trailing slash
  inject('localePathWithTrailingSlash', (route, locale) => {
    const path = app.localePath(route, locale);
    if (!path) return path; // Handle null or undefined paths
    if (path.endsWith('/') || path.includes('.')) {
      return path;
    }
    return `${path}/`;
  });
};
