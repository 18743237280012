import Vue from 'vue';

Vue.mixin({
  methods: {
    switchLocalePathWithTrailingSlash(locale) {
      const path = this.switchLocalePath(locale);
      if (!path) return path; // Handle null or undefined paths
      if (path.endsWith('/') || path.includes('.')) {
        return path;
      }
      return `${path}/`;
    },
  },
});
